import React from "react";
import styled, { keyframes, css } from "styled-components";

// partners
import img1 from '../../assets/partnership/partners/bsc.webp';
import img2 from '../../assets/partnership/partners/bsc-scan.jpg';
import img3 from '../../assets/partnership/partners/CoinGecko.png';
import img4 from '../../assets/partnership/partners/bitget-wallet.png';
import img5 from '../../assets/partnership/partners/meta-mask.png';
import img6 from '../../assets/partnership/partners/animoca.png';

// coming soon partners
import img7 from '../../assets/partnership/coming-soon/mexc.png';
import img8 from '../../assets/partnership/coming-soon/bitmart.png';
import img9 from '../../assets/partnership/coming-soon/bitrue.jpg';
import img10 from '../../assets/partnership/coming-soon/gateio.png';
import img11 from '../../assets/partnership/coming-soon/KuCoin.jpg';
import img12 from '../../assets/partnership/coming-soon/uniswap.png';
import img13 from '../../assets/partnership/coming-soon/coin-market-cap.jpg';
import img14 from '../../assets/partnership/coming-soon/pan-cake-swap.jpg';

function App() {
    const row1 = [img1, img2, img3, img4, img5, img6];

    const row2 = [img7, img8, img9, img10, img11, img12, img13, img14];

    return (
        <AppContainer id="partnership">
            <Wrapper>
                <h4
                    className="pb-4"
                    style={{ textAlign: 'center' }}
                >
                    We Are Partnered with Top Brands
                </h4>
                <h5 className='sub-heading pt-4'>Our Partners</h5>
                <Marquee>
                    <MarqueeGroup>
                        {row1.map((el) => (
                            <ImageGroup>
                                <Image src={el} />
                            </ImageGroup>
                        ))}
                    </MarqueeGroup>
                    <MarqueeGroup>
                        {row1.map((el) => (
                            <ImageGroup>
                                <Image src={el} />
                            </ImageGroup>
                        ))}
                    </MarqueeGroup>
                </Marquee>

                <h5 className='sub-heading pt-4'>Coming Soon</h5>
                <Marquee>
                    <MarqueeGroup2>
                        {row2.map((el) => (
                            <ImageGroup>
                                <Image src={el} />
                            </ImageGroup>
                        ))}
                    </MarqueeGroup2>
                    <MarqueeGroup2>
                        {row2.map((el) => (
                            <ImageGroup>
                                <Image src={el} />
                            </ImageGroup>
                        ))}
                    </MarqueeGroup2>
                </Marquee>
            </Wrapper>
        </AppContainer>
    );
}

export default App;

const AppContainer = styled.div`
    margin: 100px 0;
    color: #000000;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Wrapper = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const Marquee = styled.div`
    display: flex;
    width: 1920px;
    overflow: hidden;
    user-select: none;
    mask-image: linear-gradient(
        to right,
        hsl(0 0% 0% / 0),
        hsl(0 0% 0% / 1) 10%,
        hsl(0 0% 0% / 1) 90%,
        hsl(0 0% 0% / 0)
    );
`;

const scrollX = keyframes`
    from {
        left: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
`;

const common = css`
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    white-space: nowrap;
    width: 100%;
    animation: ${scrollX} 30s linear infinite;

    @media (max-width: 768px) {
        width: unset;
    }
`;

const MarqueeGroup = styled.div`
    ${common}
`;
const MarqueeGroup2 = styled.div`
    ${common}
    animation-direction: reverse;
    animation-delay: -3s;
`;

const ImageGroup = styled.div`
    display: grid;
    place-items: center;
    width: clamp(10rem, 1rem + 40vmin, 30rem);
    padding: calc(clamp(10rem, 1rem + 30vmin, 30rem) / 10);
`;

const Image = styled.img`
    object-fit: contain;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    border-radius: 0.5rem;
    aspect-ratio: 16/9;
    padding: 5px 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    @media (max-width: 768px) {
        width: 170px;
    }
`;