

const dataRoadMap = [

    {
        id: 1,
        time: 'Q4-2023',
        title: 'Q4-2023',
        desc: (
            <ul>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#fff"></circle></svg> Smart Contract Deployment</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#fff"></circle></svg> Website Launch</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#fff"></circle></svg> Mobile App Launch</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#fff"></circle></svg> Strategic Partnerships</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#fff"></circle></svg> Crypto Services Studio</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#fff"></circle></svg> Whitepaper</li>
            </ul>
        ),
        class: ''
    },
    {
        id: 2,
        time: 'Q1-2024',
        title: 'Q1-2024',
        desc: (
            <ul>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#fff"></circle></svg> Token Distribution (Presale)</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#fff"></circle></svg> Social Marketing Campaign</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#fff"></circle></svg> Digital Marketing Campaign</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#fff"></circle></svg> Funds Management</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#fff"></circle></svg> CGI Wallet Testnet</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#fff"></circle></svg> Press Release</li>
            </ul>

        ),
        class: 'right'
    },
    {
        id: 3,
        time: 'Q2-2024',
        title: 'Q2-2024',
        desc: (
            <ul>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#fff"></circle></svg> Smart Contract Audits</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#fff"></circle></svg> Listing on CoinMarketCap and CoinGecko</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#fff"></circle></svg> Dex Token listings (PancakeSwap)</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#fff"></circle></svg> Ecosystem Scaling</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#fff"></circle></svg> CGI AI Game Testing (Beta Version)</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#fff"></circle></svg> CGI AI Image & Video Generator (Beta Version)</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#fff"></circle></svg> Media Promotions</li>
            </ul>

        ),
        class: ''
    },
    {
        id: 4,
        time: 'Q3-2024',
        title: 'Q3-2024',
        desc: (
            <ul>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#fff"></circle></svg> Teir1 Cex listing</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#fff"></circle></svg> AI NFT creation modules</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#fff"></circle></svg> AMA with Influencers</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#fff"></circle></svg> Stacking and rewards</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#fff"></circle></svg> Airdrops for Staking</li>
            </ul>
        ),
        class: 'right'
    },
    {
        id: 5,
        time: 'Q4-2024',
        title: 'Q4-2024',
        desc: (
            <ul>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#fff"></circle></svg> Strategic partnerships</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#fff"></circle></svg> Ecosystem Scaling</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#fff"></circle></svg> CGI AI music Gen (Beta Version)</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#fff"></circle></svg> CGI Game-Fi launch (Beta Version)</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#fff"></circle></svg> Advertisement campaign</li>
            </ul>

        ),
        class: ''
    },



]

export default dataRoadMap;