import React from 'react';
// import animationData from '../../assets/cgi-splash/CGI video.json'
import animationData from '../../assets/cgi-splash/cgi_animation';
import Lottie from 'react-lottie';


const Splash = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <>
      <div className='splash-screen'>
        <Lottie options={defaultOptions} width={700} />
      </div>
    </>
  );
};


export default Splash;