import React, { useState } from 'react';
import './styles.scss';
import PkgCard from './PkgCard';

const PackageCard = () => {
    const [dataBlock] = useState({
        subheading: 'Presale',
        heading: 'Purchase your CGI here',
        heading2: '(Base Price $0.02)',
    });

    // pkgdata data
    const pricingData = [
        {
            id: 1,
            ctg: 'Diamond',
            ctgStatus: 'Best',
            price: 10000,
            perToken: 0.018,
            tokenAllocation: 555555,
            features: ['10,000 USDT investment package',
                '10% token discount ($0.018)',
                'Exclusive features and support',
                'For experienced investors',
                'Extensive diversification',
                'Ideal for long-term strategies',
            ],
        },
        {
            id: 2,
            ctg: 'Platinum',
            ctgStatus: 'Classic',
            price: 5000,
            perToken: 0.0186,
            tokenAllocation: 268817,
            features: ['5,000 USDT investment package',
                '10% token discount ($0.0186)',
                'Premium features',
                'Tailored for serious investors',
                'Moderate to high experience',
                'Significant diversification opportunity',
            ],
        },
        {
            id: 3,
            ctg: 'Gold',
            ctgStatus: 'Basic',
            price: 3000,
            perToken: 0.02,
            tokenAllocation: 150000,
            features: ['3,000 USDT investment package',
                'No discount',
                'Moderate investment size',
                'Additional features and services',
                'Suitable for moderate experience',
                'Expand portfolio with affordability',
            ],
        },
        {
            id: 4,
            ctg: 'Silver',
            ctgStatus: 'Starter',
            price: 1000,
            perToken: 0.02,
            tokenAllocation: 50000,
            features: ['1,000 USDT investment package',
                'No discount',
                'Entry-level package',
                'Basic features and services',
                'Ideal for beginners',
                'Start with a smaller investment',
            ],
        },
    ];

    return (
        <>
            <section className="package-card" id="pricing">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="block-text center">
                                <h6 className="sub-heading">
                                    <span>{dataBlock.subheading}</span>
                                </h6>
                                <h3 className="heading pd">{dataBlock.heading}</h3>
                                <h4 className="heading pb-4">{dataBlock.heading2}</h4>
                            </div>
                        </div>

                        {pricingData.map((pricing, index) => (
                            <div key={index}
                                className="col-xl-3 
                                col-md-12 
                                d-flex 
                                flex-column 
                                justify-content-center
                                mb-sm-4
                                mb-4 "
                                data-aos="fade-down-right"

                            >
                                <PkgCard
                                    id={pricing.id}
                                    ctg={pricing.ctg}
                                    ctgStatus={pricing.ctgStatus}
                                    price={pricing.price}
                                    perToken={pricing.perToken}
                                    tokenAllocation={pricing.tokenAllocation}
                                    features={pricing.features}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
};

export default PackageCard;
