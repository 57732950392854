import React, { useState } from 'react';

import Button from '../button/Button';
import PieChart from '../chart/PieChar';

function Token() {

    const tokenAllocation = {
        subheading: 'Tokenomics',
        heading: 'Token Allocation',
        desc: (
            <ul>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#5C27FE"></circle></svg> 30% 42,800,101 for presale</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#5C27FE"></circle></svg> 20% 28,533,399 for liquidity locked for 1 year</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#5C27FE"></circle></svg> 10% 14,266,700 for team locked for 8 months</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#5C27FE"></circle></svg> 20% 28,533,399 for usability</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#5C27FE"></circle></svg> 10% 14,266,700 for listing on centralized exchanges</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#5C27FE"></circle></svg> 5% 7,133,350 for Advisor share</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="#5C27FE"></circle></svg> 5% 7,133,350 for marketing campaigns</li>
            </ul>
        )
    };


    const tokenInfo = [
        { label: 'Token Name', value: 'COGNITIVE AI' },
        { label: 'Token Symbol', value: 'CGI' },
        { label: 'Token Type', value: 'BEP20' },
        { label: 'Total Supply', value: '142,666,999/Million' }
    ];


    return (
        <section className="token" id='tokenomics'>
            <div className="shape"></div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-md-12">
                        <div className="block-text pd-0" data-aos="fade-right">
                            <div className='ta-center-tab'>
                                <h6 className="sub-heading"><span>{tokenAllocation.subheading}</span></h6>
                                <h3 className="heading">{tokenAllocation.heading}</h3>
                            </div>
                            <span className="fs-19">
                                {tokenAllocation.desc}
                            </span>
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-12">
                        <div className="token__main" data-aos="fade-left" data-aos-duration="2000">
                            <ul className="token-list">
                                {tokenInfo.map((item, index) => (
                                    <li key={index}>
                                        <div className="name">
                                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.49933 4C8.49933 4.66304 8.23594 5.29893 7.7671 5.76777C7.29826 6.23661 6.66237 6.5 5.99933 6.5C5.33629 6.5 4.70041 6.23661 4.23157 5.76777C3.76273 5.29893 3.49933 4.66304 3.49933 4C3.49933 3.33696 3.76273 2.70107 4.23157 2.23223C4.70041 1.76339 5.33629 1.5 5.99933 1.5C6.66237 1.5 7.29826 1.76339 7.7671 2.23223C8.23594 2.70107 8.49933 3.33696 8.49933 4V4ZM1 13.412C1.02142 12.1002 1.55756 10.8494 2.49278 9.92936C3.42801 9.00929 4.68739 8.49365 5.99933 8.49365C7.31127 8.49365 8.57066 9.00929 9.50588 9.92936C10.4411 10.8494 10.9772 12.1002 10.9987 13.412C9.43026 14.1312 7.72477 14.5023 5.99933 14.5C4.21533 14.5 2.522 14.1107 1 13.412Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <p>{item.label}</p>
                                        </div>
                                        <h6>{item.value}</h6>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    {/* Piechart section starts here */}
                    <div className='pc' data-aos="fade-up">
                        <div className='pcw'>
                            <PieChart />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Token;