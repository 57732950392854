import img1 from '../../assets/team/1-Founder.jpeg';
import img2 from '../../assets/team/2-Co-Founder-ceo.jpeg';
import img3 from '../../assets/team/3-Dev-Tracy-Mark.jpeg';
import img4 from '../../assets/team/4-Develper-Nasheeb-Akbar.jpeg';
import img5 from '../../assets/team/5-Strategic-partner-Expert-Erma-George.jpeg';
import img6 from '../../assets/team/6-CFO-Parkash-Agarwal.jpeg';
import img7 from '../../assets/team/7-Sales-and-Marketing.jpeg';
import img8 from '../../assets/team/8-Advisor-Xiqing-Qi.png';
import img9 from '../../assets/team/9-Advisor-Dr-Scot-Norman .jpeg';
import img10 from '../../assets/team/10-BLOCK-CHAIN-and-AI-ENGINEER.jpeg';
import img11 from '../../assets/team/11-Product-manager-Austin-Werner.jpeg';
import img12 from '../../assets/team/12-Social-Media-Manager.jpeg';

const dataTeam = [

    {
        id: 1,
        img: img1,
        name: 'Martin Benjamin',
        position: 'Founder',
        intro: ''
    },
    {
        id: 2,
        img: img2,
        name: 'Glen Lowman',
        position: 'Co Founder & CEO',
        intro: 'Experienced entrepreneur with a demonstrated history of working in the ICT & Security solutions industry since twenty years. Skilled in Negotiation, Pricing strategy, comparative market research and risk management.'
    },
    {
        id: 3,
        img: img3,
        name: 'Tracy Mark',
        position: 'Development',
        intro: 'Specialize in Solidity development, as well as Rust. Expertise of smart contracts and token contracts. Working on solidity and rust skills for the last 4 years and have built experience from the ground up. She is specialized in token deployments, basic token smart contracts, to more complex smart contracts with complex security solutions.'
    },
    {
        id: 4,
        img: img4,
        name: 'Nasheeb Akbar',
        position: 'Development',
        intro: 'Blockchain Engineer with more than five years of expertise in the development domain, he have had the privilege of completing numerous specialized courses in blockchain technology and successfully launched 100+ projects.'
    },
    {
        id: 5,
        img: img5,
        name: 'Erma George',
        position: 'Strategic partner Expert',
        intro: 'With over 15 years of diverse HR experience, Human Resource Business Partner at Convexity DMCC, a leading fintech company in the Middle East. She is certified by the American Certification Institute (ACI) and have completed several professional development courses in HR.'
    },
    {
        id: 6,
        img: img6,
        name: 'Parkash Agarwal',
        position: 'Financial CFO',
        intro: 'An expert chartered accountant with 25 years of experience working with multinational companies across the globe.'
    },
    {
        id: 7,
        img: img7,
        name: 'Johny Smith',
        position: 'Sales & Marketing',
        intro: '15 years of experience managing global teams of 10 - 120 people and working with C-Suite executives, He worked with clients across various industries, including retail, publishing, financial services, and crypto.'
    },
    {
        id: 8,
        img: img8,
        name: 'Xuqing Qi',
        position: 'Advisor',
        intro: 'Dynamic and seasoned Senior Project Manager with over a decade of comprehensive experience in the fields of finance management and artificial intelligence project management.'
    },

    {
        id: 9,
        img: img9,
        name: 'Dr. Scot Norman',
        position: 'Advisor',
        intro: 'Dr. Scott is an AI veteran with 28 years experience in the field, starting at the University of Edinburgh. With a PhD in natural language processing for personalized communication.'
    },

    {
        id: 10,
        img: img10,
        name: 'Carl Egren',
        position: 'Blockchain & AI Engineer',
        intro: 'Senior IT professional for superb leadership of enterprise IT operations, driving business innovation, risk management and mitigation strategist, multinational team guidance, passionate about delivering and managing technology. Empowering businesses since 9 years.'
    },

    {
        id: 11,
        img: img11,
        name: 'Austin Werner',
        position: 'Product Manager Web3/Defi',
        intro: 'Product Manager for this Web3/AIlDeFi projects, play a pivotal role in shaping the direction and development of our decentralized platform and products with 5 years of extensive experience.'
    },

    {
        id: 12,
        img: img12,
        name: 'Abu Faisal Faras',
        position: 'Social Media Manager',
        intro: 'Social media expert with huge knowledge of launching & E-Marketing Campaigns.'
    },
]

export default dataTeam;