import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


import line from '../../assets/images/background/line-2.png'

Team2.propTypes = {
    data: PropTypes.array
};

function Team2(props) {

    const { data } = props
    return (
        <section className="team s2" id='team'>
            <div className="shape right"></div>
            <img src={line} alt="" className="img-line" />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="block-text center">
                            <h6 className="sub-heading"><span>Team</span></h6>
                            <h3 className="heading wow" data-splitting>Our Amazing Team
                                <br />
                                Members</h3>
                        </div>

                    </div>

                    {
                        data.map(idx => (
                            <div key={idx.id} className="col-xl-3 col-md-6" data-aos="zoom-in">
                                <div className="team-box">
                                    <div className="image">
                                        <Link to="#">
                                            <img src={idx.img} alt="team" />
                                        </Link>
                                    </div>
                                    <div className="content">
                                        <h5 className='name'>{`${idx.name} (${idx.position})`}</h5>

                                        <p className="" style={{ textAlign: 'center', fontSize: '15px', letterSpacing: '0.5px' }}>
                                            {idx.intro}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );
}

export default Team2;