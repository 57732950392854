
import img1 from '../images/banners/banner-01.webp';
import img2 from '../images/banners/banner-02.webp';
import img3 from '../images/banners/banner-03.webp';
import img4 from '../images/banners/banner-04.webp';
import img5 from '../images/banners/banner-05.webp';


import avt from '../images/layouts/avt-05.png'



const dataCard = [

    {
        id: 1,
        img: img1,
        title: '3D Digital Artwork',
        price: '2.26 ETH',
        avt: avt,
        name: 'Leslie Alexander',
        tag: '@leslie754'
    },
    {
        id: 2,
        img: img2,
        title: '3D Digital Artwork',
        price: '2.26 ETH',
        avt: avt,
        name: 'Leslie Alexander',
        tag: '@leslie754'
    },
    {
        id: 3,
        img: img3,
        title: '3D Digital Artwork',
        price: '2.26 ETH',
        avt: avt,
        name: 'Leslie Alexander',
        tag: '@leslie754'
    },
    {
        id: 4,
        img: img4,
        title: '3D Digital Artwork',
        price: '2.26 ETH',
        avt: avt,
        name: 'Leslie Alexander',
        tag: '@leslie754'
    },
    {
        id: 5,
        img: img5,
        title: '3D Digital Artwork',
        price: '2.26 ETH',
        avt: avt,
        name: 'Leslie Alexander',
        tag: '@leslie754'
    },


]

export default dataCard;