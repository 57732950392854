import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import menus from '../../pages/menu';
// import logo from '../../assets/cog_w3.png';
import logo from '../../assets/cgi-logo.png';
import mm from '../../assets/metamask.svg';
import './styles.scss';
import toast from 'react-hot-toast';
import { convertChainToBinanceSmartChain, getUSDTContractABI, updateUsdtBalance } from '../../utils/_helpers';

const Header = () => {
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 300);
        });
        return () => {
            setScroll({});
        }
    }, []);

    const [menuActive, setMenuActive] = useState(null);
    const handleMenuActive = () => {
        setMenuActive(!menuActive);
    };

    const [activeIndex, setActiveIndex] = useState(null);
    const handleDropdown = index => {
        setActiveIndex(index);
    };

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            const navbarHeight = document.querySelector('.header').offsetHeight;

            window.scrollTo({
                top: section.offsetTop - navbarHeight,
                behavior: "smooth"
            });
        }
    };


    return (
        <header
            id="header_main"
            className={`header ${scroll ? 'is-fixed' : ''}`}
            style={{
                background: 'transparent',
                backdropFilter: 'blur(10px)',
            }}
        >
            <div className="container big">
                <div className="row">
                    <div className="col-12">
                        <div className="header__body">
                            <div className="header__logo">
                                <Link to="/" onClick={() => scrollToSection('home')}>
                                    <img id="site-logo" src={logo} alt='cognitive.ai' width={100} />
                                </Link>
                            </div>
                            <div className="header__right">
                                <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''}`}>
                                    <ul id="menu-primary-menu" className="menu">
                                        {
                                            menus.map((data, idx) => (
                                                <li key={idx} onClick={() => handleDropdown(idx)} className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === idx ? 'active' : ''}`}>
                                                    <Link to={'/'} onClick={() => scrollToSection(data.id)}>{data.name}</Link>
                                                    {
                                                        data.namesub &&
                                                        <ul className="sub-menu">
                                                            {
                                                                data.namesub.map((submenu) => (
                                                                    <li key={submenu.id} className="menu-item"><NavLink to={submenu.links}>{submenu.sub}</NavLink></li>
                                                                ))
                                                            }
                                                        </ul>
                                                    }
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </nav>
                                <div className={`mobile-button ${menuActive ? 'active' : ''}`} onClick={handleMenuActive}><span></span></div>
                            </div>
                            <div className="header__action">

                                <button className="action-btn" onClick={() => toast.success('Under Development...')}>
                                    <span className='mm-connect-btn'>
                                        <img src={mm} alt="mm" width={23} />Connect
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
