import React from 'react';
import dataBlog from '../assets/fake-data/data-blog';
import dataCard from '../assets/fake-data/data-card';
import dataFaqs from '../assets/fake-data/data-faq';
import dataItem from '../assets/fake-data/data-item';
import dataRoadMap from '../assets/fake-data/data-roadmap';
import dataTestimonials2 from '../assets/fake-data/data-testimonials2';
import dataTeam from '../assets/fake-data/dataTeam';
import About2 from '../components/about/About2';
import Banner2 from '../components/banner/Banner2';
import Blog from '../components/blog/Blog';
import Counter from '../components/counter/Counter';
import Faqs from '../components/faqs/Faqs';
import Footer2 from '../components/footer/Footer2';
import Project2 from '../components/project/Project2';
import Roadmap2 from '../components/roadmap/Roadmap2';
import Team2 from '../components/team/Team2';
import Testimonials2 from '../components/testimonials/Testimonials2';
import Token from '../components/token/Token';
import About from '../components/about/About';
import Roadmap from '../components/roadmap/Roadmap';
import Speciality from '../components/speciality/Speciality';
import dataBox from '../assets/fake-data/data-box';
import Faq3 from '../components/faqs/Faq3';
import Footer from '../components/footer/Footer';
import PackageCard from '../components/packageCard/PackageCard';
import Partner from '../components/partner/Partner';


function Home02(props) {
    return (
        <div className='home-2 wrapper'>
            <Banner2 data={dataCard} />

            {/* Tokenomics section*/}
            <Token />

            {/* Useability section*/}
            <Speciality data={dataBox} />

            <About />

            <Partner />
            
            {/* <About2 /> */}

            {/* <Counter /> */}

            {/* <Project2 data={dataItem} /> */}

            {/* <Roadmap2 data={dataRoadMap} /> */}

            <Roadmap data={dataRoadMap} />

            <PackageCard />

            <Team2 data={dataTeam} />

            {/* <Testimonials2 data={dataTestimonials2} /> */}

            {/* <Faqs data={dataFaqs} /> */}

            {/* <Blog data={dataBlog} /> */}


            {/* <Footer2 /> */}

            <Faq3 data={dataFaqs} />

            <Footer />
        </div>
    );
}

export default Home02;