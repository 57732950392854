const menus = [
    {
        id: 'home',
        name: 'Home',
        links: '/',
    },

    {
        id: 'tokenomics',
        name: 'Tokenomics',
        links: '#',

    },
    {
        id: 'useability',
        name: 'Useability',
        links: '#',

    },
    {
        id: 'about',
        name: 'About',
        links: '#',

    },
    {
        id: 'partnership',
        name: 'Partnerships',
        links: '#',
    },
    {
        id: 'whitepaper',
        name: 'Whitepaper',
        links: '#',

    },
    {
        id: 'roadmap',
        name: 'Roadmap',
        links: '#',
    },
    {
        id: 'pricing',
        name: 'Presale',
        links: '#',
    },
    {
        id: 'team',
        name: 'Team',
        links: '#',
    },

]

export default menus;