// partners
import img1 from '../../assets/partnership/partners/bsc.webp';
import img2 from '../../assets/partnership/partners/bsc-scan.jpg';
import img3 from '../../assets/partnership/partners/CoinGecko.png';
import img4 from '../../assets/partnership/partners/bitget-wallet.png';
import img5 from '../../assets/partnership/partners/meta-mask.png';
import img6 from '../../assets/partnership/partners/animoca.png';

// coming soon partners
import img7 from '../../assets/partnership/coming-soon/mexc.png';
import img8 from '../../assets/partnership/coming-soon/bitmart.png';
import img9 from '../../assets/partnership/coming-soon/bitrue.jpg';
import img10 from '../../assets/partnership/coming-soon/gateio.png';
import img11 from '../../assets/partnership/coming-soon/KuCoin.jpg';
import img12 from '../../assets/partnership/coming-soon/uniswap.png';
import img13 from '../../assets/partnership/coming-soon/coin-market-cap.jpg';
import img14 from '../../assets/partnership/coming-soon/pan-cake-swap.jpg';


export const dataPartner = [

    {
        id: 1,
        img: img1,
        width: 200,
    },
    {
        id: 2,
        img: img2,
        width: 200,

    },
    {
        id: 3,
        img: img3,
        width: 220,
    },
    {
        id: 4,
        img: img4,
        width: 120,

    },
    {
        id: 5,
        img: img5,
        width: 200,
    },
    {
        id: 6,
        img: img6,
        width: 150,
    },


]

export const dataComingSoonPartner = [

    {
        id: 1,
        img: img7,
        width: 200,
    },
    {
        id: 2,
        img: img8,
        width: 200,

    },
    {
        id: 3,
        img: img9,
        width: 180,
    },
    {
        id: 4,
        img: img10,
        width: 200,

    },
    {
        id: 5,
        img: img11,
        width: 200,
    },
    {
        id: 6,
        img: img12,
        width: 200,
    },
    {
        id: 7,
        img: img13,
        width: 200,
    },
    {
        id: 8,
        img: img14,
        width: 220,
    },
]