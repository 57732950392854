import React, { useState } from 'react';



function Speciality(props) {

    const { data } = props;

    const [dataBlock] = useState(
        {
            subheading: 'Useability',
            heading: 'Unlock the Potential usability of AI bug detection, Media Content Gen, NFTs, GameiFi & Storage with Cognitive AI',
            desc: 'Experience the future of digital ownership with Cognitive AI, your gateway to the world of NFTs.',

        }
    )
    return (
        <section className="speciality" id='useability'>
            <div className="shape right"></div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="block-text center">
                            <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                            <h3 className="heading pd">{dataBlock.heading}</h3>
                            {/* <p className="fs-18 ">{dataBlock.desc}</p> */}
                        </div>
                    </div>
                    {
                        data.map(idx => (
                            <div key={idx.id} className="col-xl-3 col-md-6" data-aos="zoom-in-up">
                                <div className="speciality-box" >
                                    <div className="icon">
                                        <img src={idx.img} alt="cognitive_ai" />
                                    </div>
                                    <h5 className="title fs-24">{idx.title}</h5>
                                    <p className='fs-18'>{idx.desc}</p>
                                    <h3 className="number">0{idx.id}</h3>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>

    );
}

export default Speciality;