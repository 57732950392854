
import img1 from '../images/icon/icon1.png'
import img2 from '../images/icon/icon2.png'
import img3 from '../images/icon/icon3.png'
import img4 from '../images/icon/icon4.png'


const dataBox = [
    {
        id: 1,
        img: img4,
        title: 'AI Bug detection & fixing',
        desc: 'Implementing rigorous testing protocols aids in AI bug detection'
    },
    {
        id: 2,
        img: img2,
        title: 'Integration of AI & Blockchain',
        desc: 'Integrating AI with blockchain enhances efficiency and security'
    },
    {
        id: 3,
        img: img3,
        title: 'Melodic Magic',
        desc: 'Create music using CGI AI music generator'
    },
    {
        id: 4,
        img: img1,
        title: 'Image & Video Wizardry',
        desc: 'Create Images and Social Media Videos/ads with ease using CGI AI media Generators'
    },
    {
        id: 5,
        img: img4,
        title: 'Game Haven',
        desc: 'CGI AI GameiFi (Play 2 Earn Games)'
    },

    {
        id: 6,
        img: img2,
        title: 'NFT Forge',
        desc: 'NFT Generation module to create customized NFTs using CGI AI'
    },
    {
        id: 7,
        img: img3,
        title: 'Reward Harvest',
        desc: 'CGI AI Staking Rewards'
    },
    {
        id: 8,
        img: img4,
        title: 'Data Vault',
        desc: 'Data monetization and storage'
    },

]

export default dataBox;