import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import { Route, Routes } from 'react-router-dom';

import routes from './pages';
import Page404 from './pages/404';
import Header from './components/header/Header';
import Splash from './components/splash/Splash';
import { Toaster } from 'react-hot-toast';

function App() {
    const [loading, setLoading] = useState(true);
    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            AOS.init({
                duration: 2000
            });
            setLoading(false);
            setTimeout(() => setShowContent(true), 1000); // Delay content appearance for smoother transition
        }, 5000);
    }, []);

    return (
        <div>
            <Toaster />
            {!loading && (
                <>
                    <Header />
                    <div style={{ opacity: showContent ? 1 : 0, transition: 'opacity 1s ease-in-out' }}>
                        <Routes>
                            {routes.map((data, idx) => (
                                <Route key={idx} path={data.path} element={data.component} exact />
                            ))}
                            <Route path='*' element={<Page404 />} />
                        </Routes>
                    </div>
                </>
            )}
            {loading && <Splash />}
        </div>
    );
}

export default App;
