import React from 'react';
import Ticker from 'react-ticker';
import './styles.scss';
import logo from '../../assets/cog_w3.png';


const MyTicker = ({ speed, height }) => {

    return (
        <>
            <Ticker speed={speed} height={height}>
                {() => (
                    <div className='ticker-style'>
                        <h1>
                            Implementing rigorous testing protocols aids in AI bug detection -
                            Integrating AI with blockchain enhances efficiency and security -
                            Create Images with ease using CGI AI Image Generator -
                            Create Social Media Videos/ads with ease using CGI AI Video Generators -
                            Create music  using CGI AI music generator -
                            CGI AI GameiFi (Play 2 Earn Games) -
                            NFT Generation module to create customized NFTs using CGI AI -
                            CGI AI Staking Rewards -
                            Data monetization and storage
                        </h1>
                    </div>
                )}
            </Ticker>
        </>
    );
};

export default MyTicker;
