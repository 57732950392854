


const dataFaqs = [
    {
        id: 1,
        title: 'What is Cognitive AI?',
        text: 'Cognitive AI is a comprehensive ecosystem that combines AI-powered insights, decentralized tools, and innovative services to empower individuals in the world of Cryptocurrencies and blockchain.',
        show: ''

    },
    {
        id: 2,
        title: 'What services does Cognitive AI offer?',
        text: 'Cognitive AI offers a range of services including AI-powered crypto insights, decentralized tools like CGI Wallet and CGI Pay, privacy-focused DEX, NFT platforms, and more.',
        show: ''

    },
    {
        id: 3,
        title: 'What is CGI Token?',
        text: 'CGI Token is the native cryptocurrency of the Cognitive AI ecosystem. It serves as a utility for various services, and transactions within the platform.',
        show: ''

    },
    {
        id: 5,
        title: 'Is my data safe within the Cognitive AI ecosystem?',
        text: 'Yes, privacy and security are paramount. Cognitive AI works advanced encryption and decentralized technologies to protect your data and transactions.',
        show: ''

    },
    {
        id: 7,
        title: ' Is Cognitive AI accessible to newcomers in the crypto space?',
        text: 'Absolutely. Cognitive AI offers educational resources, AI-based learning, and user-friendly interfaces to cater to both newcomers and experienced users.',
        show: ''

    },
    {
        id: 8,
        title: 'How can I stay updated with Cognitive AI developments?',
        text: 'Stay connected through our official website, social media channels, and community forums to receive updates on new features, services, and developments.',
        show: ''

    },

]

export default dataFaqs;