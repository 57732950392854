import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'react-bootstrap-accordion';

import img from '../../assets/images/faq/faq-img.webp'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

Faq3.propTypes = {
    data: PropTypes.array
};

function Faq3(props) {
    const { data } = props;

    const [dataBlock] = useState(
        {
            subheading: 'FAQs',
            heading: 'Common Questions',
            desc: 'Below is a list of frequently asked questions and answers from partners and 3D artist. Please check this FAQ first before contacting us.'

        }
    )

    const [dataTab] = useState([
        {
            id: 1,
            title: 'Cryptocurrency',
        },
        {
            id: 2,
            title: 'NFT Token',
        },
        {
            id: 3,
            title: 'Collection',
        },
        {
            id: 4,
            title: 'Crypto Trading',
        },

    ]);
    return (
        <section className="faq s3">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-md-12" data-aos="fade-right">
                        <div className="block-text" >
                            <div className='ta-center-tab'>
                                <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                                <h3 className="heading">{dataBlock.heading}</h3>
                            </div>
                            <p className='fs-18'>{dataBlock.desc}</p>
                        </div>
                        <div className="faq__main flat-tabs">
                            {
                                data.map(item => (
                                    <div className="content-inner" key={item.id}>
                                        <div className="flat-accordion">
                                            <Accordion show={item.show} key={item.id} title={item.title} className='flat-toggle h6'>
                                                <p className="toggle-content">{item.text}</p>
                                            </Accordion>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                    </div>

                    <div className="col-xl-6 col-md-12" data-aos="fade-left" data-aos-duration="2000">
                        <div className="image" >
                            <img src={img} alt="cognitive_ai" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Faq3;