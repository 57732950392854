import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import logo from '../../assets/cgi-logo.png'
import Social from '../social/Social';


function Footer(props) {

    const [dataBlock] = useState(
        {
            heading: 'Get Newsletter',
            desc: 'Get udpated with news, tips & tricks'
        }
    )

    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 500) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <footer className="footer">

            <div className="shape"></div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        {/* <div className="footer__main">
                            <div className="block-text center">

                                <h3 className="heading">{dataBlock.heading}</h3>
                                <p >{dataBlock.desc}</p>


                            </div>
                            <form action="#" className="form">
                                <div className="form-group">
                                    <input type="email" className="form-control" placeholder="Your email" />

                                </div>
                                <button className="action-btn"><span>Subscribe</span></button>
                            </form>
                        </div> */}

                        <div className="footer__bottom">
                            <img src={logo} alt="cognitve_ai" width={70} />

                            <div className="center mb--15">
                                <ul className="list">
                                    {/* <li><Link to="/">Home</Link></li>
                                    <li><Link to="/nfts">Explore</Link></li>
                                    <li><Link to="/about">About</Link></li>
                                    <li><Link to="/nfts">Collections</Link></li>
                                    <li><Link to="/team">Team</Link></li>
                                    <li><Link to="/contact">Contact</Link></li> */}
                                    <p style={{ color: '#fff', fontSize: '17px' }}>Copyright © 2024, Congnitive AI</p>
                                </ul>
                            </div>
                            <Social />
                        </div>


                    </div>
                </div>
            </div>

            {
                isVisible &&
                <Link to='#' onClick={scrollToTop} id="scroll-top"><span className="icon-arrow-top"></span></Link>
            }
        </footer>
    );
}

export default Footer;