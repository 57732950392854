import React, { useState } from 'react';

import img1 from '../../assets/images/about/about-01.webp'
import img2 from '../../assets/images/about/about-02.webp'
import img3 from '../../assets/images/about/about-03.webp'
import img4 from '../../assets/images/about/about-04.webp'
import img5 from '../../assets/images/about/about-05.webp'
import Button from '../button/Button';



function About(props) {

    const [dataImg] = useState([
        {
            id: 1,
            img: img3,
            class: 'img1'
        },
        {
            id: 2,
            img: img2,
            class: 'img2'
        },
        {
            id: 3,
            img: img5,
            class: 'img3'
        },
        {
            id: 4,
            img: img4,
            class: 'img4'
        },
        {
            id: 5,
            img: img1,
            class: 'img5'
        },
    ])

    const [dataBlock] = useState(
        {
            subheading: 'About',
            heading: 'Creative Revolution Begins!',
            desc1: 'At CGI AI, we\'re pioneering the future of creativity and innovation. Our mission is to empower individuals and businesses alike with cutting-edge AI technology, transforming the way we create, engage, and interact in the digital realm. With our suite of tools and services, ranging from image and video generation to music creation, game development, and NFT customization, we\'re unlocking boundless possibilities for expression and exploration. Whether you\'re an artist, marketer, developer, or enthusiast, CGI AI is your gateway to a world where imagination knows no limits. ',
            desc2: 'Backed by a dedicated team of visionaries and bolstered by strategic partnerships, we\'re committed to driving forward the evolution of digital content creation. Join us on this journey as we redefine the landscape of creativity, one innovation at a time.',
        }
    )
    return (
        <section className="about" id='about'>
            <div className="shape"></div>
            <div className="container">
                <div className="row rev">
                    <div className="col-xl-6 col-md-12">
                        <div className="about__right" data-aos="fade-right">
                            <div className="images">
                                {
                                    dataImg.map(idx => (
                                        <img key={idx.id} className={idx.class} src={idx.img} alt="cyfonii" />
                                    ))
                                }

                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-12">
                        <div className="block-text" data-aos="fade-left">
                            <div className='ta-center-tab'>
                                <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                                <h3 className="heading">{dataBlock.heading}</h3>
                            </div>
                            <p className="mb-17 fs-18">{dataBlock.desc1}</p>
                            <p className="mb-17 fs-18">{dataBlock.desc2}</p>
                            {/* <Button link='/about' title='More About Us' /> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;