import React, { useState } from 'react';
import tick_s1 from '../../assets/images/svg/tick-s1.svg';
import tick_s2 from '../../assets/images/svg/tick-s2.svg';

const PkgCard = ({
    id,
    ctg,
    ctgStatus,
    price,
    perToken,
    tokenAllocation,
    features
}) => {
    const [hovered, setHovered] = useState(false);

    const handleHover = () => {
        setHovered(true);
        if (id > 1) {
            const element = document.getElementById('pkg-card-1');
            if (element) {
                element.classList.remove('pkg-card-s1');
                element.classList.add('pkg-card-s2');
            }
        }
    };

    const handleMouseLeave = () => {
        setHovered(false);
        if (id > 1) {
            const element = document.getElementById('pkg-card-1');
            if (element) {
                element.classList.add('pkg-card-s1');
                element.classList.remove('pkg-card-s2');
            }
        }
    };

    return (
        <div
            id={`pkg-card-${id}`} // Assigning unique id to each package card
            className={id === 1 ? 'pkg-card-s1' : hovered && id > 1 ? 'pkg-card-s1' : 'pkg-card-s2'}
            onMouseEnter={handleHover}
            onMouseLeave={handleMouseLeave}
        >
            <div className='pkg-category'>
                <h5>{ctg}</h5>
                <span className='pkg-category-badge'>{ctgStatus}</span>
            </div>

            <div className="pkg-price">
                <h4>{`$${price}`}</h4>
                <h6 className='pb-1'>{`$${perToken} ${price > 3000 ? 'Discounted Price' : 'Price'}`}</h6>
                <h6>{tokenAllocation} Token Allocation</h6>
            </div>

            <div className='pkg-btn'>
                <button className='pkg-purchase-btn' onClick={() => alert(price)}>Get Started</button>
            </div>

            <div className='pkg-features'>
                <strong>Features</strong>
                <ul>
                    {features.map((feature, index) => (
                        <li key={index}>
                            <img src={(id === 1 || (hovered && id > 1)) ? tick_s1 : tick_s2} alt="tick" className='tick-svg' />
                            {feature}
                        </li>
                    ))}
                </ul>

            </div>
        </div>
    );
};

export default PkgCard;
