import img_0 from './images/img_0.png';
import img_1 from './images/img_1.png';
import img_2 from './images/img_2.png';
import img_3 from './images/img_3.png';
import img_4 from './images/img_4.png';
import img_5 from './images/img_5.png';
import img_6 from './images/img_6.png';

const animationData = {
    v: "5.12.2",
    fr: 29.9700012207031,
    ip: 0,
    op: 150.000006109625,
    w: 1920,
    h: 1080,
    nm: "Pre-comp 2",
    ddd: 0,
    assets: [
        {
            id: "image_0",
            w: 986,
            h: 101,
            u: "",
            p: img_0,
            e: 0
        },
        {
            id: "image_1",
            w: 56,
            h: 2,
            u: "",
            p: img_1,
            e: 0
        },
        {
            id: "image_2",
            w: 56,
            h: 2,
            u: "",
            p: img_2,
            e: 0
        },
        {
            id: "image_3",
            w: 862,
            h: 75,
            u: "",
            p: img_3,
            e: 0
        },
        {
            id: "image_4",
            w: 213,
            h: 161,
            u: "",
            p: img_4,
            e: 0
        },
        {
            id: "image_5",
            w: 456,
            h: 453,
            u: "",
            p: img_5,
            e: 0
        },
        {
            id: "image_6",
            w: 1921,
            h: 1081,
            u: "",
            p: img_6,
            e: 0
        },
        {
            id: "comp_0",
            nm: "tagline",
            fr: 29.9700012207031,
            layers: [
                {
                    ddd: 0,
                    ind: 1,
                    ty: 2,
                    nm: "Layer 4",
                    refId: "image_1",
                    sr: 1,
                    ks: {
                        o: {
                            a: 0,
                            k: 100,
                            ix: 11
                        },
                        r: {
                            a: 0,
                            k: 0,
                            ix: 10
                        },
                        p: {
                            a: 0,
                            k: [
                                493.362,
                                868.708,
                                0
                            ],
                            ix: 2,
                            l: 2
                        },
                        a: {
                            a: 0,
                            k: [
                                27.84,
                                0.934,
                                0
                            ],
                            ix: 1,
                            l: 2
                        },
                        s: {
                            a: 0,
                            k: [
                                100,
                                100,
                                100
                            ],
                            ix: 6,
                            l: 2
                        }
                    },
                    ao: 0,
                    ip: 0,
                    op: 300.00001221925,
                    st: 0,
                    bm: 0
                },
                {
                    ddd: 0,
                    ind: 2,
                    ty: 2,
                    nm: "Layer 3",
                    refId: "image_2",
                    sr: 1,
                    ks: {
                        o: {
                            a: 0,
                            k: 100,
                            ix: 11
                        },
                        r: {
                            a: 0,
                            k: 0,
                            ix: 10
                        },
                        p: {
                            a: 0,
                            k: [
                                1427.567,
                                868.708,
                                0
                            ],
                            ix: 2,
                            l: 2
                        },
                        a: {
                            a: 0,
                            k: [
                                27.84,
                                0.934,
                                0
                            ],
                            ix: 1,
                            l: 2
                        },
                        s: {
                            a: 0,
                            k: [
                                100,
                                100,
                                100
                            ],
                            ix: 6,
                            l: 2
                        }
                    },
                    ao: 0,
                    ip: 0,
                    op: 300.00001221925,
                    st: 0,
                    bm: 0
                },
                {
                    ddd: 0,
                    ind: 3,
                    ty: 2,
                    nm: "Layer 2",
                    refId: "image_3",
                    sr: 1,
                    ks: {
                        o: {
                            a: 0,
                            k: 100,
                            ix: 11
                        },
                        r: {
                            a: 0,
                            k: 0,
                            ix: 10
                        },
                        p: {
                            a: 0,
                            k: [
                                963.871,
                                860.101,
                                0
                            ],
                            ix: 2,
                            l: 2
                        },
                        a: {
                            a: 0,
                            k: [
                                430.621,
                                37.463,
                                0
                            ],
                            ix: 1,
                            l: 2
                        },
                        s: {
                            a: 0,
                            k: [
                                100,
                                100,
                                100
                            ],
                            ix: 6,
                            l: 2
                        }
                    },
                    ao: 0,
                    ip: 0,
                    op: 300.00001221925,
                    st: 0,
                    bm: 0
                }
            ]
        }
    ],
    layers: [
        {
            ddd: 0,
            ind: 1,
            ty: 2,
            nm: "Layer 5",
            refId: "image_0",
            sr: 1,
            ks: {
                o: {
                    a: 0,
                    k: 100,
                    ix: 11
                },
                r: {
                    a: 0,
                    k: 0,
                    ix: 10
                },
                p: {
                    a: 0,
                    k: [
                        960.464,
                        751.753,
                        0
                    ],
                    ix: 2,
                    l: 2
                },
                a: {
                    a: 0,
                    k: [
                        492.719,
                        50.481,
                        0
                    ],
                    ix: 1,
                    l: 2
                },
                s: {
                    a: 0,
                    k: [
                        100,
                        100,
                        100
                    ],
                    ix: 6,
                    l: 2
                }
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
                {
                    inv: false,
                    mode: "a",
                    pt: {
                        a: 1,
                        k: [
                            {
                                i: {
                                    x: 0.833,
                                    y: 0.833
                                },
                                o: {
                                    x: 0.167,
                                    y: 0.167
                                },
                                t: 60,
                                s: [
                                    {
                                        i: [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        o: [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        v: [
                                            [
                                                -27.745,
                                                -33.272
                                            ],
                                            [
                                                -57.745,
                                                -25.272
                                            ],
                                            [
                                                -55.745,
                                                136.728
                                            ],
                                            [
                                                -17.745,
                                                132.728
                                            ]
                                        ],
                                        c: true
                                    }
                                ]
                            },
                            {
                                t: 105.000004276738,
                                s: [
                                    {
                                        i: [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        o: [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        v: [
                                            [
                                                1032.255,
                                                -31.272
                                            ],
                                            [
                                                -57.745,
                                                -25.272
                                            ],
                                            [
                                                -55.745,
                                                136.728
                                            ],
                                            [
                                                1042.255,
                                                134.728
                                            ]
                                        ],
                                        c: true
                                    }
                                ]
                            }
                        ],
                        ix: 1
                    },
                    o: {
                        a: 0,
                        k: 100,
                        ix: 3
                    },
                    x: {
                        a: 0,
                        k: 0,
                        ix: 4
                    },
                    nm: "Mask 1"
                }
            ],
            ip: 0,
            op: 300.00001221925,
            st: 0,
            ct: 1,
            bm: 0
        },
        {
            ddd: 0,
            ind: 2,
            ty: 0,
            nm: "tagline",
            refId: "comp_0",
            sr: 1,
            ks: {
                o: {
                    a: 0,
                    k: 100,
                    ix: 11
                },
                r: {
                    a: 0,
                    k: 0,
                    ix: 10
                },
                p: {
                    a: 0,
                    k: [
                        960,
                        540,
                        0
                    ],
                    ix: 2,
                    l: 2
                },
                a: {
                    a: 0,
                    k: [
                        960,
                        540,
                        0
                    ],
                    ix: 1,
                    l: 2
                },
                s: {
                    a: 0,
                    k: [
                        100,
                        100,
                        100
                    ],
                    ix: 6,
                    l: 2
                }
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
                {
                    inv: false,
                    mode: "a",
                    pt: {
                        a: 1,
                        k: [
                            {
                                i: {
                                    x: 0.833,
                                    y: 0.833
                                },
                                o: {
                                    x: 0.167,
                                    y: 0.167
                                },
                                t: 90,
                                s: [
                                    {
                                        i: [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        o: [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        v: [
                                            [
                                                411.344,
                                                808
                                            ],
                                            [
                                                390.386,
                                                828
                                            ],
                                            [
                                                390,
                                                928
                                            ],
                                            [
                                                412,
                                                924
                                            ]
                                        ],
                                        c: true
                                    }
                                ]
                            },
                            {
                                t: 127.000005172816,
                                s: [
                                    {
                                        i: [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        o: [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        v: [
                                            [
                                                1519.344,
                                                804
                                            ],
                                            [
                                                390.386,
                                                828
                                            ],
                                            [
                                                390,
                                                928
                                            ],
                                            [
                                                1520,
                                                920
                                            ]
                                        ],
                                        c: true
                                    }
                                ]
                            }
                        ],
                        ix: 1
                    },
                    o: {
                        a: 0,
                        k: 100,
                        ix: 3
                    },
                    x: {
                        a: 0,
                        k: 0,
                        ix: 4
                    },
                    nm: "Mask 1"
                }
            ],
            w: 1920,
            h: 1080,
            ip: 0,
            op: 300.00001221925,
            st: 0,
            ct: 1,
            bm: 0
        },
        {
            ddd: 0,
            ind: 3,
            ty: 2,
            nm: "Layer 6",
            refId: "image_4",
            sr: 1,
            ks: {
                o: {
                    a: 0,
                    k: 100,
                    ix: 11
                },
                r: {
                    a: 0,
                    k: 0,
                    ix: 10
                },
                p: {
                    a: 0,
                    k: [
                        962.448,
                        404.79,
                        0
                    ],
                    ix: 2,
                    l: 2
                },
                a: {
                    a: 0,
                    k: [
                        106.446,
                        80.42,
                        0
                    ],
                    ix: 1,
                    l: 2
                },
                s: {
                    a: 1,
                    k: [
                        {
                            i: {
                                x: [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                y: [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            o: {
                                x: [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                y: [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            t: 0,
                            s: [
                                0,
                                0,
                                100
                            ]
                        },
                        {
                            t: 90.0000036657751,
                            s: [
                                100,
                                100,
                                100
                            ]
                        }
                    ],
                    ix: 6,
                    l: 2
                }
            },
            ao: 0,
            ip: 0,
            op: 300.00001221925,
            st: 0,
            ct: 1,
            bm: 0
        },
        {
            ddd: 0,
            ind: 4,
            ty: 2,
            nm: "Layer 7",
            refId: "image_5",
            sr: 1,
            ks: {
                o: {
                    a: 0,
                    k: 100,
                    ix: 11
                },
                r: {
                    a: 1,
                    k: [
                        {
                            i: {
                                x: [
                                    0.833
                                ],
                                y: [
                                    0.833
                                ]
                            },
                            o: {
                                x: [
                                    0.167
                                ],
                                y: [
                                    0.167
                                ]
                            },
                            t: 0,
                            s: [
                                0
                            ]
                        },
                        {
                            t: 60.0000024438501,
                            s: [
                                -720
                            ]
                        }
                    ],
                    ix: 10
                },
                p: {
                    a: 0,
                    k: [
                        960.459,
                        408.669,
                        0
                    ],
                    ix: 2,
                    l: 2
                },
                a: {
                    a: 0,
                    k: [
                        227.813,
                        226.484,
                        0
                    ],
                    ix: 1,
                    l: 2
                },
                s: {
                    a: 1,
                    k: [
                        {
                            i: {
                                x: [
                                    0.833,
                                    0.833,
                                    0.833
                                ],
                                y: [
                                    0.833,
                                    0.833,
                                    0.833
                                ]
                            },
                            o: {
                                x: [
                                    0.167,
                                    0.167,
                                    0.167
                                ],
                                y: [
                                    0.167,
                                    0.167,
                                    0.167
                                ]
                            },
                            t: 0,
                            s: [
                                0,
                                0,
                                100
                            ]
                        },
                        {
                            t: 90.0000036657751,
                            s: [
                                100,
                                100,
                                100
                            ]
                        }
                    ],
                    ix: 6,
                    l: 2
                }
            },
            ao: 0,
            ip: 0,
            op: 300.00001221925,
            st: 0,
            ct: 1,
            bm: 0
        },
        // {
        //     ddd: 0,
        //     ind: 5,
        //     ty: 2,
        //     nm: "Layer 1",
        //     refId: "image_6",
        //     sr: 1,
        //     ks: {
        //         o: {
        //             a: 0,
        //             k: 100,
        //             ix: 11
        //         },
        //         r: {
        //             a: 0,
        //             k: 0,
        //             ix: 10
        //         },
        //         p: {
        //             a: 0,
        //             k: [
        //                 960,
        //                 540,
        //                 0
        //             ],
        //             ix: 2,
        //             l: 2
        //         },
        //         a: {
        //             a: 0,
        //             k: [
        //                 960.25,
        //                 540.25,
        //                 0
        //             ],
        //             ix: 1,
        //             l: 2
        //         },
        //         s: {
        //             a: 0,
        //             k: [
        //                 100,
        //                 100,
        //                 100
        //             ],
        //             ix: 6,
        //             l: 2
        //         }
        //     },
        //     ao: 0,
        //     ip: 0,
        //     op: 300.00001221925,
        //     st: 0,
        //     bm: 0
        // }
    ],
    markers: [],
    props: {}
}

export default animationData;