import React from 'react';
import Chart from 'react-apexcharts';

const PieChart = () => {
    const chartOptions = {
        labels: ['Presale', 'Liquidity', 'Team Share', 'Usability', 'Centralized Exchanges', 'Advisor Share', 'Marketing Campaigns'],
        legend: {
            show: false
        },
        fill: {
            type: 'gradient',
        },
        stroke: {
            colors: ['#000']
        },

    };
    const series = [30, 20, 10, 20, 10, 5, 5];

    return (
        <div className="pie-chart">
            <Chart options={chartOptions} series={series} type="donut" />
        </div>
    );
};

export default PieChart;
