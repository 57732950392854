import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../button/Button';

import img from '../../assets/images/background/line.png'

Roadmap.propTypes = {
    data: PropTypes.array
};

function Roadmap(props) {
    const { data } = props;

    const [dataBlock] = useState(
        {
            subheading: 'Roadmap',
            heading: 'The Roadmap of Cognitive AI',
        }
    )

    return (
        <section className="roadmap" id='roadmap'>
            <img src={img} alt="" className="img-line" />
            <div className="shape"></div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="block-text center">
                            <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                            <h3 className="heading pd">{dataBlock.heading}</h3>
                        </div>

                        <div className="roadmap__main" data-aos="fade-up" data-aos-duration="2000">
                            {
                                data.map(idx => (
                                    <div key={idx.id} className={`roadmap-box ${idx.class}`} >
                                        {/* <div className="time">{idx.time}</div> */}
                                        <div className="content">
                                            <h5 className="title fs-24">{idx.title}</h5>
                                            <span className="text fs-18">{idx.desc}</span>
                                        </div>
                                    </div>
                                ))
                            }

                            <div className="icon"></div>
                            <div className="icon bottom"></div>


                        </div>
                        <div className="button">
                            <Button title='2025 Coming soon' link='#' />
                        </div>

                    </div>

                </div>
            </div>
        </section>

    );
}

export default Roadmap;